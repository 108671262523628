import axios from "axios";
// import swal from "sweetalert/dist/sweetalert.min.js";
let serverurl = "http://apiv2.pacificweb.com.ph/"; 
// let serverurl ="http://192.168.5.21/backend/beta_api/";
// let serverurl = "http://beta.api.pacificweb.com.ph/";
// let serverurl ="http://192.168.1.44/api_pacific/";
// let serverurl ="http://localhost/beta_api/";
let key = "?key= @20190208&type=web";
// let key2 = "?key=12345ABCFleet";

export function getData(method, param) {
    return new Promise((resolve, reject) => {
        axios
            .post(serverurl + method + key, {
                param
            })
            .then(res => {
                resolve(res.data);
            });
    });
}

export function sqlData(sql,token,method_data,controller) {
    return new Promise((resolve, reject) => {
        var config = {
            method: method_data,
            url: 'https://apiv2.pacificweb.com.ph/'+controller,
            headers: { 
              'Authorization': 'Bearer'+' '+token, 
              'Content-Type': 'application/json'
            },
            data : sql
          };
          axios(config)
            .then(res => {
                resolve(res.data);
            });
    });
}

export function sqlDataDelete(token,method_data,controller) {
    return new Promise((resolve, reject) => {
        var config = {
            method: method_data,
            url: 'https://api.innovattosoft.com/'+controller,
            headers: { 
              'Authorization': 'Bearer'+' '+token, 
              'Content-Type': 'application/json'
            }
          };
          axios(config)
            .then(res => {
                resolve(res.data);
            });
    });
}

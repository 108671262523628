import React from 'react';
import logo from './logo.svg';
import './App.css';
import {
  HashRouter as Router,
  Route,
  Redirect
} from "react-router-dom";
import { createMuiTheme, Theme, ThemeProvider } from '@material-ui/core/styles';
import Survey from './components/Survey';
import Response from './components/Responses';
function App() {
  return (
    <div className="App">
      <Router>
        <Route exact path="/:type" component={Survey} />
        <Route path="/responses" component={Response} />
      </Router>
    </div>
  );
}

export default App;

import React, { Component, useState, useEffect } from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import useStyles from './templates/style';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// import Teachers from './Teachers';
// import { useSelector, useDispatch } from 'react-redux'
// interface TabPanelProps {
//     children?: React.ReactNode;
//     index: any;
//     value: any;
// }

export default function Dashboard() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const [state, setState] = React.useState({
        empId: '',
        suggestion: '',
        comment: '',
        busNUm: '',
        emp_name: ''
    });


    const handleChangeBusNum = (event) => {
        setState({
            ...state,
            busNum: event.target.value,
        });
    };

    
    useEffect(() => {
        
    }, []);

    return (
        <div className={classes.root} style={{ marginTop: 35 }}>
           <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">BUS NUMBER</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={state.busNum}
                    onChange={handleChangeBusNum}
                >
                    <MenuItem value={"b1"}>Bus 1</MenuItem>
                    <MenuItem value={"b2"}>Bus 2</MenuItem>
                    <MenuItem value={"b3"}>Bus 3</MenuItem>
                    <MenuItem value={"b4"}>Bus 4</MenuItem>
                    <MenuItem value={"b5"}>Bus 5</MenuItem>
                    <MenuItem value={"b6"}>Bus 6</MenuItem>
                    <MenuItem value={"b7"}>Bus 7</MenuItem>
                    <MenuItem value={"b8"}>Bus 8</MenuItem>
                    <MenuItem value={"b9"}>Bus 9</MenuItem>
                    <MenuItem value={"b10"}>Bus 10</MenuItem>
                    <MenuItem value={"b11"}>Bus 11</MenuItem>
                    <MenuItem value={"b12"}>Bus 12</MenuItem>
                    <MenuItem value={"b13"}>Bus 13</MenuItem>
                </Select>
            </FormControl>
        </div>
    );
}
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import React from 'react';
import useStyles from './style';
import Logo from './asset/logo192.png';
import { sqlData, sqlDataDelete } from './api';
import Dialog from '@material-ui/core/Dialog';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24),
    createData('Ice cream sandwich', 237, 9.0, 37),
    createData('Eclair', 262, 16.0, 24),
    createData('Cupcake', 305, 3.7, 67),
    createData('Gingerbread', 356, 16.0, 49),
];

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

export default function Dashboard() {
    const [open, setOpen] = React.useState(false);

    const [state, setState] = React.useState({
        empId: '',
        suggestion: '',
        comment: '',
        busNUm: '',
        emp_name: '',
        rating: 5
    });


    const handleChangeRating = (event) => {
        setState({
            ...state,
            rating: event.target.value,
        });
    };

    const onChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value
        });

    };
    const onSubmit = (e) => {
        e.preventDefault()

        const data = {
            emp_id: state.empId,
            suggestion: state.suggestion,
            comment: state.comment,
            
            bus_num: window.location.href.split('/')[4],
            emp_name: state.empName,
            rating: state.rating
        }

        sqlData(data, 'aaaaa', 'POST', 'surveyjg').then(result => {
            console.log(result);
            setOpen(true)
        })

        // console.log(data);

    }

    const classes = useStyles();


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (

        <div >
            <Card style={{ width: 400, margin: 'auto', marginTop: 100 }} variant='outlined'>
                <div className={classes.details}>
                    <CardContent className={classes.content}>
                        <div>
                            <img src={Logo} style={{ width: '100%' }} draggable='false' />
                        </div>
                        <Typography variant="h6" gutterBottom style={{ marginTop: 10, textAlign: 'center' }}>
                            Your Comment and Suggestion
                        </Typography>
                        <form onSubmit={(e) => onSubmit(e)}>
                            <TextField required fullWidth style={{ marginBottom: 15, marginTop: 50 }} label="Employee Number" variant="outlined" onChange={onChange} name='empId' />
                            <TextField fullWidth style={{ marginBottom: 15 }} label="Employee Name" variant="outlined" onChange={onChange} name='empName' />
                            <TextField
                                id="outlined-textarea"
                                label="Please Input your suggestion"
                                placeholder="Enter"
                                multiline
                                rows={4}
                                variant="outlined"
                                fullWidth
                                name='suggestion'
                                value={state.task}
                                onChange={onChange}
                                required
                                style={{ marginBottom: 15 }}
                            />

                            <TextField
                                id="outlined-textarea"
                                label="Please Input your Comment"
                                placeholder="Enter"
                                multiline
                                rows={4}
                                variant="outlined"
                                fullWidth
                                name='comment'
                                value={state.task}
                                onChange={onChange}
                                required
                                style={{ marginBottom: 15 }}
                            />
                            <FormControl variant="outlined" required className={classes.formControl} >
                                <InputLabel id="demo-simple-select-label">Rating</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={state.busNum}
                                    onChange={handleChangeRating}
                                >
                                    <MenuItem value={5}>5 - Very Satisfied</MenuItem>
                                    <MenuItem value={4}>4 - Satisfied</MenuItem>
                                    <MenuItem value={3}>3 - Neutral</MenuItem>
                                    <MenuItem value={2}>2 - Dissatisfied</MenuItem>
                                    <MenuItem value={1}>1 - Very Dissatisfied</MenuItem>
                                </Select>
                            </FormControl>
                            <Button type='submit' variant="contained" color="primary" fullWidth style={{ marginBottom: 5, marginTop: 15 }}>
                                Submit
                            </Button>
                        </form>
                    </CardContent>
                </div>
            </Card>
            <Dialog fullScreen open={open} onClose={handleClose}>
                <Typography style={{ width: 450, margin: 'auto', marginTop: 100 }} variant="h4" component="h5" gutterBottom>
                    Thank you for sending your comments and suggestions<br />
                </Typography>

            </Dialog>
        </div>
    );
}